const initialState = {
    rate: [],
    report: [],
    month: [],
    week: [],
    day: [],
    reportAdmin: [],
    ranking: [],
    reportHistory: [],
    accounts : [],
    isUpdated: false
}
const BillingReducer = function (state = initialState, action) {
    switch (action.type) {
            case 'SET_REPORT_TYPE': {

            return {
                ...state,
                [action.timeFrame]: action.report,
                isUpdated: true
            }
        }    
             case 'SET_REPORT_HISTORY': {

            return {
                ...state,
                reportHistory: action.reportHistory,
                isUpdated: true
            }
        }
            case 'GET_ACCOUNT': {
      return {
        ...state,
        accounts: action.user
        
      }
    }
             default: {
            return { ...state }
        }
            
    }
}


export default BillingReducer

